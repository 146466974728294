<template>
	<div class="app-container">
		<Box :type="'info'" :active="true">
			<div slot="box-body">
				<ul class="rawUl">
					<li>Platile cu cardul vor fi virate dupa ce ati achitat factura pentru comisioane.</li>
				</ul>
			</div>
		</Box>
		<el-card class="box-card not-billed-com">
			<div slot="header" class="clearfix">
				<span>{{$t('payments.card.header-1')}}</span>&nbsp;
				<el-select size="mini" v-model="selectedMonth" :placeholder="$t('payments.card.chooseMonth')"
					@change="handleMonthChange" no-data-text="Nu aveti comenzi cu cardul" filterable>
					<el-option v-for="item in availableMonths" :key="item.value" :label="item.label"
						:value="item.value">
					</el-option>
				</el-select>
				<el-button type="primary" size="mini" @click="exportPayments">{{$t('payments.card.buttonExport')}}
				</el-button>
			</div>
			<template v-if="orders.length > 0">
				<el-table :data="orders" style="width: 100%" size="mini">
					<el-table-column prop="orders_id" label="ID" width="100">
					</el-table-column>
					<el-table-column prop="customers_name" :label="$t('general.client')">
					</el-table-column>
					<el-table-column prop="order_total" :label="$t('general.value') + '**'">
						<template slot-scope="scope">
							{{scope.row.order_total}} RON
						</template>
					</el-table-column>
					<el-table-column :label="$t('payments.card.bill')">
						<template slot-scope="scope">
							{{scope.row.serie}} {{scope.row.nr_fact}}
						</template>
					</el-table-column>
					<el-table-column prop="id_borderou" label="Borderou" width="180">
					</el-table-column>
					<el-table-column :label="$t('payments.card.status')" width="180">
						<template slot-scope="scope">
							{{statusValues[scope.row.status]}}
						</template>
					</el-table-column>
				</el-table>
				<div class="total-value">Total:
					<strong>{{this.total}} RON</strong>
				</div>
				<!--<div class="notice">**{{$t('payments.card.notice')}}</div>-->
			</template>
			<template v-else>
				<el-alert :closable="false" :title="$t('payments.card.warning')" type="warning"
					:description="$t('payments.card.alert')" show-icon>
				</el-alert>
			</template>

		</el-card>
		<div class="separator"></div>
		<el-card class="box-card not-billed-com">
			<div slot="header" class="clearfix">
				<span>{{$t('payments.card.header-2')}}</span>&nbsp;
				<el-select size="mini" v-model="minMonth" :placeholder="$t('payments.card.chooseMonth')"
					no-data-text="Nu aveti comenzi cu cardul" filterable>
					<el-option v-for="item in availableMonths" :key="item.value" :label="item.label"
						:value="item.value">
					</el-option>
				</el-select>
				<el-select size="mini" v-model="maxMonth" :placeholder="$t('payments.card.chooseMonth')"
					no-data-text="Nu aveti comenzi cu cardul" filterable>
					<el-option v-for="item in availableMonths" :key="item.value" :label="item.label"
						:value="item.value">
					</el-option>
				</el-select>
				<el-button type="primary" size="mini" @click="totalMonths">Calculeaza totalul pe luni</el-button>
			</div>
			<br>
			<div>
				<span>{{totalForInterval == 0 ? '' : ('Total: ' + totalForInterval + ' RON')}}</span>
			</div>

		</el-card>
	</div>
</template>

<script>
	import {
		getCardPaymentMonths,
		getCardOrdersByMonth,
		exportPayments,
		totalPaymentsForMonthsInterval
	} from '@/api/plati';
	import sortBy from 'lodash.sortby';
	import has from 'lodash.has';
	import capitalize from 'lodash.capitalize';

	const Box = () =>
		import('vue-info-box-element');

	export default {
		components: {
			Box
		},
		data() {
			return {
				months: [],
				selectedMonth: '',
				orders: [],
				total: 0,
				statusValues: {
					25: 'Finalizata',
					24: 'Stornata'
				},
				minMonth: '',
				maxMonth: '',
				totalForInterval: 0
			};
		},
		methods: {
			getMonths() {
				getCardPaymentMonths().then((res) => {
					if (typeof res.message === 'undefined' || res.message.length < 1) return false;
					this.months = sortBy(res.message, [], ['desc']);
					this.selectedMonth = this.months[0];
					this.$nextTick(() => {
						this.getCardOrdersByMonth();
					});
				});
			},
			handleMonthChange(val) {
				if (typeof val === 'undefined') return false;
				this.getCardOrdersByMonth(val);
			},
			getCardOrdersByMonth(month = '') {
				const params = {
					month: month !== '' ? month : this.selectedMonth
				};
				this.orders = [];
				this.total = 0;
				getCardOrdersByMonth(params).then((res) => {
					if (typeof res.message === 'undefined' || typeof res.message.orders === 'undefined') {
						return false;
					}
					this.orders = res.message.orders;
					this.total = res.message.total;
				});
			},
			exportPayments() {
				exportPayments({
					month: this.selectedMonth
				}).then((res) => {
					if (!has(res, 'message') || !has(res.message, 'file')) return false;
					const link = document.createElement('a');
					const file = 'export_plati_card_cel.xlsx';
					link.href = res.message.file;
					link.download = file;
					document.body.appendChild(link);
					link.click();
					document.body.removeChild(link);
					link.remove();
				}).catch((e) => {
					this.$reportToSentry(e, {
						extra: {
							fn: 'exportPayments',
							params: {
								month: this.selectedMonth
							}
						}
					});
					console.log(e);
				});
			},
			totalMonths() {
				totalPaymentsForMonthsInterval({
					minMonth: this.minMonth,
					maxMonth: this.maxMonth
				}).then((res) => {
					this.totalForInterval = res.message.total;
				}).catch((e) => {
					this.$reportToSentry(e, {
						extra: {
							fn: 'totalPaymentsForMonthsInterval',
							params: {
								minMonth: this.minMonth,
								maxMonth: this.maxMonth
							}
						}
					});
					console.log(e);
				});
			}
		},
		computed: {
			availableMonths() {
				return this.months.map((item, i) => {
					return {
						value: item,
						label: capitalize(this.$moment(item, 'MM/YYYY').format('MMMM Y'))
					};
				});
			},
		},
		mounted() {
			this.getMonths();
		}
	};
</script>
<style rel="stylesheet/scss" lang="scss" scoped>
	.total-value {
		float: right;
		margin: 10px 5px;
	}
</style>